/* global IntersectionObserver */
// @flow
import * as React from 'react';

import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';

type LazyLoadedImageProps = {
  children: ({ mayLoad: boolean, ref?: ?(HTMLElement => void)}) => React.Node,
};

let globalObserver = null;
const elsMappedToSetInView = new WeakMap();

const observerSettings = { rootMargin: '1000px', };
const observerCB = (entries, observer) => {
  entries.forEach(({ target, isIntersecting, }) => {
    if (isIntersecting) {
      const setInView = elsMappedToSetInView.get(target);
      setInView && setInView(true);
      elsMappedToSetInView.delete(target);
      observer.unobserve(target);
    }
  });
};


function LazyLoadedImageWithObserver({
  children,
}) {
  const [ inView, setInView, ] = React.useState(false);
  const elRef = React.useRef(null);
  const ref = React.useCallback(
    el => {
      if (inView) return;
      if (!globalObserver) {
        globalObserver = new IntersectionObserver(observerCB, observerSettings);
      }
      if (elRef.current && elRef.current !== el) {
        globalObserver.unobserve(elRef.current);
        elsMappedToSetInView.delete(elRef.current);
      }
      elRef.current = el;
      if (!el) return;
      elsMappedToSetInView.set(el, setInView);
      globalObserver.observe(el);
    },
    [ inView, ]
  );

  React.useEffect(
    () => () => {
      if (elRef.current && globalObserver) {
        globalObserver.unobserve(elRef.current);
        elsMappedToSetInView.delete(elRef.current);
      }
    },
    []
  );

  return children({ mayLoad: inView, ref, });
}

function LazyLoadedImage({
  children,
}: LazyLoadedImageProps): React.Node {
  const [ lazyMode, setLazyMode, ] = React.useState(null);
  React.useEffect(
    () => {
      setLazyMode('loading' in HTMLImageElement.prototype ? 'native' : 'observer');
    },
    []
  );

  return lazyMode === 'native'
    ? children({ mayLoad: true, })
    : lazyMode === 'observer'
      ? (
        <LazyLoadedImageWithObserver>
          {children}
        </LazyLoadedImageWithObserver>
      )
      : null;
}

export default LazyLoadedImage;
