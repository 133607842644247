import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { attrsPropType, } from '../../../propTypes/attrsPropType';
import { stylesPropType, } from '../../../propTypes/stylesPropType';
import useWebViewChecker from '../../../hooks/useWebViewChecker';

const propTypes = {
  /**
   * An object of attrbutes to set on the DOM element.
   * Passed to the underlying react element
   */
  attrs: attrsPropType,
  /**
   * Specifies a text for describing the image.
   * Used as a textual alternative when the image
   * fails to load and is used by assitive tech
   */
  alt: PropTypes.string,
  /**
   * Class(es) to be added to the DOM element.
   * Automatically generated by Fela, do not enter manually.
   */
  className: PropTypes.string,
  /**
   * Determines if the image will be surrounded by
   * a wrapper to prevent content jumping
   */
  hasWrapper: PropTypes.bool,
  /** An array of strings describing a media query */
  media: PropTypes.string,
  /**
   * A sting describing the media query (optional) and
   * the rendered width of the image (not the file!).
   */
  sizes: PropTypes.string,
  /** The src attribute specifies the URL of the image. */
  src: PropTypes.string,
  /** Specifies the URL of the image to use in different situations. */
  srcSet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, ]),
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, ]),
  loading: PropTypes.oneOf([ 'auto', 'lazy', 'eager', ]),
  /** The HTML tag used to render the element */
  tagName: PropTypes.oneOf([ 'img', 'source', ]),
  /** The tooltip that pops up when the image is hovered */
  title: PropTypes.string,
  /** The image's mimetype */
  type: PropTypes.string,
  miscStyles: stylesPropType,
  onLoad: PropTypes.func,
  /** A ref to be passed down to the DOM `<img />` element */
  imgRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(PropTypes.elementType), }),
  ]),
};

const defaultProps = {
  attrs: null,
  alt: null,
  className: null,
  hasWrapper: true,
  media: null,
  sizes: null,
  src: null,
  srcSet: null,
  width: null,
  height: null,
  loading: null,
  tagName: 'img',
  title: null,
  type: null,
  miscStyles: null,
  onLoad: null,
  imgRef: null,
};

// Styles that cause the image to occupy its wrapper's dimensions
const styles = ({ hasWrapper, miscStyles, theme, isWebView, }) => ({
  ...(isWebView ? {
    color: 'transparent',
    '-webkit-touch-callout': 'none',
  } : {}),
  ...(hasWrapper
    ? {
      height: '100%',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
      extend: [
        ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
      ],
    }
    : {
      maxWidth: '100%',
      maxHeight: '100%',
      extend: [
        ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
      ],
    }),
  userSelect: 'none',
});

function ImgSource({
  hasWrapper,
  attrs,
  alt,
  miscStyles,
  className,
  media,
  sizes,
  src,
  width,
  height,
  loading,
  srcSet,
  tagName,
  title,
  type,
  imgRef,
  onLoad,
}) {
  const isWebView = useWebViewChecker();
  const { css, } = useFela({ hasWrapper, miscStyles, isWebView, });
  const ImgSourceElement = tagName;

  const imgClasses = tagName === 'img'
    ? `${css(styles)}${className ? ` ${className}` : ''}` : null;

  return (
    <ImgSourceElement
      {...(alt ? { alt, } : {})}
      {...(tagName === 'img'
        ? {
          ref: imgRef,
          onLoad,
          src,
          className: imgClasses,
          ...(title ? { title, } : {}),
          ...(width && height && hasWrapper ? { width, height, } : {}),
          ...(loading ? { loading, } : {}),
        }
        : {})}
      {...(tagName === 'source'
        ? {
          srcSet: !srcSet || srcSet.length === 0 ? src : srcSet,
          ...(media ? { media, } : {}),
          ...(type ? { type, } : {}),
        }
        : {})}
      {...(sizes ? { sizes, } : {})}
      {...(srcSet ? { srcSet, } : {})}
      {...attrs}
    />
  );
}

ImgSource.propTypes = propTypes;
ImgSource.defaultProps = defaultProps;

export default ImgSource;
