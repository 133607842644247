// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import type {
  ComponentPropResponsiveObject,
  StyleProps,
} from '@haaretz/htz-css-tools';

import setColor from '../../utils/setColor';
import type { attrFlowType, } from '../../flowTypes/attrTypes';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';

export type GridPropType = string | ComponentPropResponsiveObject<string>[];

type TeaserPropTypes = {
  children?: React.Node,
  /** attributes to be passed to the DOM element */
  attrs: ?attrFlowType,
  /**
   * The css-grid-layout template pattern. Can be an array of responsive objects
   * or a string. Areas should be named `media`, `content` and `footer`
   *
   * example:
   * areasTemplate={`
   *   "media content"
   *   "media footer"
   * `}
   *
   * or:
   * areasTemplate={[
   *   { until: 's', value: '"media" "content" "footer"', },
   *   {
   *     from: 's',
   *     value: `
   *       "media content"
   *       "media footer"
   *     `,
   *   },
   * ]}
   */
  areasTemplate: ?GridPropType,
  /**
   * The background-color of the headline
   * Can be:
   *   - A `string` representing a named color.
   *   - A `tuple` of two `string`s, the first representing.
   *     a named color, and the second representing a variant
   *     of that named color.
   *   - An array of objects representing media queries, in
   *     the following structure:
   *     ```
   *     {
   *       from?: string,
   *       until?: string,
   *       misc?: string,
   *       value: string or tuple, as mentioned above,
   *     }
   *     ```
   */
  backgroundColor: ?ColorPropType,
  colTemplate: ?GridPropType,
  rowTemplate: ?GridPropType,
  colGap: ?GridPropType,
  rowGap: ?GridPropType,
  gridGap: ?GridPropType,
  gridArea: ?string,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: ?StyleProps,
  /**
   * Forwarded ref
   */
  fwRef?: ?{ current: HTMLElement | null, },
};

Teaser.defaultProps = {
  children: null,
  attrs: null,
  areasTemplate: null,
  backgroundColor: null,
  colTemplate: null,
  rowTemplate: null,
  colGap: null,
  rowGap: null,
  gridGap: null,
  gridArea: null,
  miscStyles: null,
  fwRef: null,
};

export default function Teaser({
  attrs,
  children,
  areasTemplate,
  backgroundColor,
  colTemplate,
  rowTemplate,
  colGap,
  rowGap,
  gridGap,
  gridArea,
  miscStyles,
  fwRef,
}: TeaserPropTypes): React.Node {
  const className = useFela({
    backgroundColor,
    areasTemplate,
    colTemplate,
    rowTemplate,
    gridGap,
    gridArea,
    colGap,
    rowGap,
    miscStyles,
  }).css(teaserStyle);

  return (
    <article className={className} {...attrs} ref={fwRef}>
      {children}
    </article>
  );
}

// //////////////////////////////////////////////////////////////////////
//                               STYLES                                //
// //////////////////////////////////////////////////////////////////////

function parseGridProp(cssPropName: string, propValue: GridPropType, theme) {
  return parseComponentProp<GridPropType>(cssPropName, propValue, theme.mq);
}

function teaserStyle({
  theme,
  backgroundColor,
  areasTemplate,
  colTemplate,
  rowTemplate,
  colGap,
  rowGap,
  gridGap,
  gridArea,
  miscStyles,
}) {
  const { cardStyle, } = theme;
  const extend = [
    parseComponentProp<ColorPropType>(
      'backgroundColor',
      backgroundColor || cardStyle.cardBackgroundColor,
      theme.mq,
      setColor,
      theme.color
    ),
  ];

  if (areasTemplate) extend.push(parseGridProp('gridTemplateAreas', areasTemplate, theme));
  if (colTemplate) extend.push(parseGridProp('gridTemplateColumns', colTemplate, theme));
  if (rowTemplate) extend.push(parseGridProp('gridTemplateRows', rowTemplate, theme));
  if (gridGap) extend.push(parseGridProp('gridGap', gridGap, theme));
  if (colGap) extend.push(parseGridProp('gridColumnGap', colGap, theme));
  if (rowGap) extend.push(parseGridProp('gridRowGap', rowGap, theme));
  if (miscStyles) extend.push(...parseStyleProps(miscStyles, theme.mq, theme.type));

  return {
    display: 'grid',
    gridArea,
    /* TODO: Removed overflow property due to Chrome issue - `fr` is not calculated as expected
    when a grid item without an explicit height has overflow set to anything but `visible`.
    Restore `overflow: hidden` if and when issue resolves. */
    // // The contents of teasers should always be contained within them
    // overflow: 'hidden',
    // Contain the block-link covering the entire card
    position: 'relative',
    width: '100%',
    extend,
  };
}
