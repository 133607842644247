import { buildURLs, buildUrl, } from '../../utils/buildImgURLs';
import { aspectRatios, } from './consts';
import { isImage, isEmbed, isSeriesArticles, isRelatedArticle, isHtmlNode, isAdSlot, isClickTracker, } from '../../utils/validateType';

export const formatImageTitle = ({ caption, credit, theme, }) => `${caption || ''}${caption && credit ? ', ' : ''}${credit ? `${credit}` : ''}`;

export const getImageAspect = viewMode => {
  if (viewMode === 'wide') return 'belgrade';
  if (viewMode === 'fullimage') return 'full';
  return viewMode;
};

export function getDimensions(files, { positionInImgArray = 0, imgOptions, sourceOptions, }) {
  const { transforms, } = imgOptions || sourceOptions;
  const { height: heightFromTransforms, width: widthFromTransforms, ...rest } = Array.isArray(
    transforms
  )
    ? transforms[0]
    : transforms;

  const aspect = getImageAspect(rest.aspect);

  if (heightFromTransforms && widthFromTransforms) {
    // force aspect ratio according to image's specified transforms
    return { height: heightFromTransforms, width: widthFromTransforms, };
  }


  if (aspect === 'full') {
    const { height, width, } = (files[positionInImgArray] || {});
    return { height, width, };
  }

  // default to the fixed aspect ratio for all other aspects
  const { height, width, } = aspectRatios[aspect];
  return { height, width, };
}

function extractArgs(
  { contentId, files, },
  { positionInImgArray = 0, imgOptions, sourceOptions, }
) {
  const { transforms, } = imgOptions || sourceOptions;
  const transformsArray = Array.isArray(transforms) ? transforms : [ transforms, ];

  // Always use the first image in the imageArray,
  // unless a valid positionInImgArray field is provided (e.g. infographics)
  const { path, aspects, } = files?.[positionInImgArray] || files?.[0] || {};

  const imgData = {
    imgName: path,
    aspects: {
      full: {
        height: files[positionInImgArray].height,
        width: files[positionInImgArray].width,
      },
      ...aspects || {},
    },
  };

  return {
    contentId,
    imgData,
    transformsArray,
  };
}

export function getSources(image, options, isAnimationReduced) {
  const { contentId, imgData, transformsArray, } = extractArgs(
    image,
    options,
    isAnimationReduced,
  );

  const hasSrcSet = transformsArray.length > 1;
  // If more than one transform is defined,
  // Use the first transform to build the `src` attribute
  // And all transforms to build the `srcset` attribute
  return [
    buildUrl(contentId, imgData, transformsArray[0], isAnimationReduced),
    ...(hasSrcSet ? buildURLs(contentId, imgData, transformsArray, { isAnimationReduced, }) : []),
  ];
}

export function getSrcSet(data, options, isAnimationReduced) {
  const { contentId, imgData, transformsArray, } = extractArgs(data, options);
  return buildURLs(contentId, imgData, transformsArray, { isAnimationReduced, });
}

export const getImagesOfArticle = articleData => {
  const result = [];
  const checkers = [ isSeriesArticles, isEmbed, isRelatedArticle, isAdSlot, isClickTracker, isHtmlNode, ];
  if (checkers.some(checker => checker(articleData))) {
    return [];
  }

  Object.keys(articleData).forEach(key => (Array.isArray(articleData[key])
    ? articleData[key].forEach(item => {
      if (!item) return;

      if (
        item && isImage(item)
      ) {
        !item.imageUrlLinkGroup && result.push(item);
      }
      else if (Array.isArray(item.body)) {
        item.body.forEach(element => {
          result.push(...getImagesOfArticle(element));
        });
      }
      else if (Array.isArray(item.items)) {
        item.items.forEach(element => {
          result.push(...getImagesOfArticle(element));
        });
      }
      else if (typeof item === 'object' && !Array.isArray(item)) {
        result.push(...getImagesOfArticle(item));
      }
    })
    : articleData[key]
    && typeof articleData[key] === 'object'
    && isImage(articleData[key])
    && !articleData[key].imageUrlLinkGroup
    && result.push(articleData[key]))
  );
  return result;
};
