import React from 'react';
import { useFela, } from 'react-fela';
import config from 'config';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import IconHtzLoader from '../Icon/icons/IconHtzLoader';
import IconTmLoader from '../Icon/icons/IconTmLoader';

const siteNumber = config.has('siteNumber') ? config.get('siteNumber') : 80;
const Loader = siteNumber === 10 || siteNumber === 20
  ? IconTmLoader
  : IconHtzLoader;

export default function WebViewPreloader() {
  const isWebView = useWebViewChecker();
  const { css, theme, } = useFela();

  if (isWebView) {
    return (
      <div className={css({
        position: 'absolute',
        top: '0',
        start: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: theme.color('bg', 'base'),
        opacity: '0.8',
        zIndex: '10',
      })}
      >
        <Loader size={7} color="primary" />
      </div>
    );
  }

  return null;
}
