// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import ImgSource from './elements/ImgSource';
import LazyLoadedImage from './LazyLoadedImage';
import DefaultImage from '../DefaultImage/DefaultImage';
import { getSources, getDimensions, formatImageTitle, } from './utils';
import setColor from '../../utils/setColor';
import useIsAnimationReduced from '../../hooks/useIsAnimationReduced';

import type { ImagePropsType, } from '../../flowTypes/ImagePropsType';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';
import type { attrFlowType, } from '../../flowTypes/attrTypes';
import WebViewPreloader from '../WebViewPreloader/WebViewPreloader';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import usePrint from '../../hooks/Page/usePrint';

type ImageWrapperProps = {
  width: number | string,
  height: number | string,
  bgc: ?ColorPropType,
  miscStyles: ?StyleProps,
  children: React.Node,
  as?: string,
}

type ImageInnerProps = {
  lazyLoad: ?boolean,
  hasWrapper: ?boolean,
  alt: ?string,
  title: ?string,
  src: ?string,
  srcSet: ?(string | string[]),
  sizes: ?string,
  height: number | string,
  width: number | string,
  isPresentational: ?boolean,
  attrs: ?attrFlowType,
  theme: Object,
  onLoad?: Function,
}

type PlainImageProps = {
  children: ({ mayLoad: boolean, ref?: ?(HTMLElement => void), }) => React.Node,
};

const ImageWrapperStyle = ({ bgc, height, theme, width, miscStyles, }) => ({
  height: '0',
  paddingBottom: `${(height / width) * 100}%`,
  position: 'relative',
  width: '100%',
  extend: [
    parseComponentProp('backgroundColor', bgc || [ 'image', 'bgc', ], theme.mq, setColor, theme.color),
    // Trump all other styles with those defined in `miscStyles`
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

function ImageWrapper({
  as,
  children,
  width,
  height,
  bgc,
  miscStyles,
}: ImageWrapperProps): React.Node {
  const className = useFela({ children, width, height, bgc, miscStyles, }).css(ImageWrapperStyle);

  const Component = as || 'div';

  return (
    <Component className={className}>
      {children}
    </Component>
  );
}

ImageWrapper.defaultProps = {
  as: 'div',
};

Image.defaultProps = {
  attrs: null,
  bgcolor: null,
  hasWrapper: true,
  isPresentational: false,
  lazyLoad: false,
  miscStyles: null,
  asWrapper: null,
  onLoad: null,
};


function adaptFiles(files = []) {
  if (!Array.isArray(files)) {
    return [];
  }

  return files.map(file => ({
    ...file,
    ...(file.aspects?.full || {}),
    path: file.imgName,
  }));
}

function adaptImage(image) {
  return {
    ...image,
    // $FlowFixMe
    files: adaptFiles(image?.imgArray),
  };
}

export default function Image(props: ImagePropsType): React.Node {
  const { imgOptions, asWrapper, onLoad, } = props;
  const { isPrint, } = usePrint();

  let image = props.image || props.data;

  // $FlowFixMe
  if (/^[1,7]\.\d+$/.test(image?.contentId)) {
    image = adaptImage(image);
  }


  const isAnimationReduced = useIsAnimationReduced();
  const { theme, } = useFela();
  const { transforms, } = imgOptions;
  if (image == null) {
    const { aspect, width, height, } = Array.isArray(transforms) ? transforms[0] : transforms;
    return <DefaultImage transforms={{ aspect, width, height, }} />;
  }

  const { attrs, bgcolor, isPresentational, lazyLoad, miscStyles, hasWrapper, } = props || {};
  const { accessibility, files, type, credit, caption, } = image;

  if (isPrint) return null;

  if (!files?.[0]?.path) return null;

  const { sizes, } = imgOptions || {};
  const isPicture = type === 'infographic';
  if (isPicture) {
    console.error(
      `The data structure of the "${image.contentId}" image is of a picture element, not an image.
       Please use the "<Picture />" component`
    );
    return null;
  }

  if (isPresentational && (attrs && (!!attrs.role || !!attrs['aria-hidden']))) {
    console.warn(
      'When "isPresentational" prop value is true, "role" and "aria-hidden" are set automatically'
    );
  }

  const { height, width, } = getDimensions(files, props);

  const sources = getSources(image, props, isAnimationReduced);
  const [ src, ...srcSet ] = sources;

  const Wrapper = hasWrapper ? ImageWrapper : React.Fragment;

  return (
    <Wrapper
      {...hasWrapper ? {
        width,
        height,
        miscStyles,
        bgc: bgcolor,
        as: asWrapper,
      } : {}}
    >
      <ImageInner
        lazyLoad={lazyLoad}
        hasWrapper={hasWrapper}
        alt={accessibility}
        title={formatImageTitle({ caption, credit, theme, })}
        src={src}
        srcSet={srcSet.length ? srcSet : null}
        sizes={sizes}
        height={height}
        width={width}
        isPresentational={isPresentational}
        attrs={attrs}
        theme={theme}
        onLoad={onLoad}
      />
    </Wrapper>
  );
}

const PlainImage = ({ children, }: PlainImageProps): React.Node => children({ mayLoad: true, });

function ImageInner({
  lazyLoad,
  hasWrapper,
  alt,
  title,
  src,
  srcSet,
  sizes,
  height,
  width,
  isPresentational,
  attrs,
  theme,
  onLoad,
}: ImageInnerProps): React.Node {
  const [ loaded, setLoaded, ] = React.useState(!lazyLoad);
  const isWebView = useWebViewChecker();
  const Renderer = lazyLoad ? LazyLoadedImage : PlainImage;

  return (
    <React.Fragment>
      {loaded ? null : <WebViewPreloader />}
      <Renderer>
        {({ mayLoad, ref, }) => (
          <ImgSource
            imgRef={ref}
            alt={alt}
            hasWrapper={hasWrapper}
            title={title}
            src={mayLoad ? src : null}
            {...(mayLoad ? { srcSet, } : {})}
            {...(hasWrapper ? {
              height,
              width,
            } : {})}
            onLoad={(...args) => {
              if (typeof onLoad === 'function') {
                onLoad(...args);
              }
            }}
            {...(lazyLoad || isWebView ? {
              loading: 'lazy',
              onLoad: (...args) => {
                if (typeof onLoad === 'function') {
                  onLoad(...args);
                }

                setLoaded(true);
              },
              miscStyles: {
                transition: theme.getTransitionString('opacity', 1, 'easeOut'),
                opacity: loaded ? 1 : 0,
              },
            } : {})}
            sizes={sizes}
            attrs={
            isPresentational
              ? {
                ...attrs,
                role: 'presentation',
                'aria-hidden': true,
              }
              : attrs
          }
          />
        )}
      </Renderer>
    </React.Fragment>
  );
}

ImageInner.defaultProps = {
  onLoad: null,
};
