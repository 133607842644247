import config from 'config';
import { isClickTracker, } from './validateType';

const SHOULD_ADD_HASH = 'shouldAddHashToLink';

function getTeaserHref({ inputTemplate, path, hash, link, }) {
  if (isClickTracker({ inputTemplate, })) {
    return link;
  }

  const shouldAddHash = config.has(SHOULD_ADD_HASH) && config.get(SHOULD_ADD_HASH);

  const qs = shouldAddHash && hash
    ? path.includes('?') ? `&v=${hash}` : `?v=${hash}`
    : '';

  return path + qs;
}

export default getTeaserHref;
