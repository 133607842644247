// @flow

export function isClickTracker(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'ClickTracker';
}

export function isClickTrackerWrapper(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'ClickTrackerWrapper';
}

export function isDfp(candidate: any): boolean %checks {
  return candidate && (candidate.inputTemplate === 'DfpBanner' || candidate.inputTemplate === 'DfpBannerRichTextElement' || candidate.inputTemplate === 'DfpBannerHTZ');
}

export function isAdSlot(candidate: any): boolean %checks {
  return candidate && (candidate.inputTemplate === 'AdSlot');
}

export function isGridElement(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'com.tm.GridElementGroup';
}

export function isMiddleRuller(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'com.tm.promotion.banner.MiddleRuler';
}

export function isHeaderNewsGroup(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'HeaderNewsList';
}

export function isList(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'htz_list_List';
}

export function isMainBlock(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'MainBlock';
}

export function isTabElement(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'com.tm.TabViewElement';
}

export function isMobileListWrapper(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'com.polobase.whtzMobileSiteListsWrapper';
}

export function isTeaser(candidate: any): boolean %checks {
  return candidate && (candidate.inputTemplate === 'TeaserData' || candidate.inputTemplate === 'TeaserHTZ');
}

export function isError(candidate: any): boolean %checks {
  return candidate && candidate.kind === 'error';
}

export function isImage(candidate: any): boolean %checks {
  return candidate && (candidate.kind === 'image' || candidate.inputTemplate === 'htz_image_Image');
}

export function isEmbed(candidate: any): boolean %checks {
  return candidate && candidate.kind === 'Embed';
}

export function isGallery(candidate: any): boolean %checks {
  return candidate && candidate.kind === 'Gallery';
}

export function isRssFeed(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'com.tm.ExternalRssElement';
}

export function isIframe(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'com.tm.iframeElement';
}

export function isHtmlElement(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'HtmlElement';
}

export function isCountdown(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'Counter';
}

export function isRelatedArticle(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'RelatedArticle';
}
export function isSeriesArticles(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'SeriesArticles';
}

export function isHtmlNode(candidate: any): boolean %checks {
  return candidate && candidate.inputTemplate === 'HtmlNode';
}

export default {
  isClickTracker,
  isClickTrackerWrapper,
  isCountdown,
  isMobileListWrapper,
  isDfp,
  isAdSlot,
  isGridElement,
  isHeaderNewsGroup,
  isHtmlElement,
  isList,
  isMainBlock,
  isTabElement,
  isTeaser,
  isError,
  isImage,
  isIframe,
  isEmbed,
  isGallery,
  isMiddleRuller,
  isRssFeed,
  isRelatedArticle,
  isHtmlNode,
};
