// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import HtzLink from '../HtzLink/HtzLink';
import getTeaserHref from '../../utils/getTeaserHref';

import type { ClickTrackerBannerType, } from '../../flowTypes/ClickTrackerBannerType';
import type { TeaserDataType, } from '../../flowTypes/TeaserDataType';

type TeaserMediaPropsType = {
  data: TeaserDataType | ClickTrackerBannerType,
  /**
   * Don't wrap the media element with an `<a>` element.
   * Useful for interactive  content.
   */
  disableAnchor: boolean,
  onClick: ?() => void,
  children: React.Node,
  miscStyles: ?StyleProps,
};

TeaserMedia.defaultProps = {
  children: null,
  disableAnchor: false,
  onClick: null,
  miscStyles: null,
};

export default function TeaserMedia({
  data,
  children,
  disableAnchor,
  onClick,
  miscStyles,
}: TeaserMediaPropsType): React.Node {
  const { css, theme, } = useFela();
  const className = css({
    gridArea: 'media',
    position: 'relative',
    zIndex: '1',
    extend: miscStyles
      ? parseStyleProps(miscStyles, theme.mq, theme.type)
      : undefined,
  });
  return (
    <div className={className}>
      {disableAnchor ? (
        children
      ) : (
        <HtzLink
          onClick={onClick}
          href={getTeaserHref(data)}
          target={data.linkTarget}
          attrs={{ tabIndex: -1, }}
        >
          {children}
        </HtzLink>
      )}
    </div>
  );
}
